const config = {
  newMark: {
    title: 'Livan',
    slug: 'livan',
  },
  nuxtIcon: {
    class: 'icon',
    size: '24px',
  },
  catalog: {
    offersLimit: 18,
  },
  cookieKeys: {
    themeCookieKey: 'rx-theme',
    visitedCookieKey: 'rx-visited-ids',
    utmCookieKey: 'rx-utm',
    favoritesCookieKey: 'rx-favs',
  },
  categories: {
    cars: {
      route: 'cars',
      categoryGraphql: 'cars',
    },
    new: {
      route: 'cars-new',
      categoryGraphql: 'new',
    },
  } as const,
  credit: {
    period: {
      values: {
        min: 2,
        11: 6,
        22: 12,
        33: 24,
        44: 36,
        55: 48,
        66: 60,
        77: 72,
        88: 84,
        max: 96,
      },
      postfix: null,
      indexToInitialDisplay: -1,
    },
    payment: {
      values: {
        min: 0,
        12: 10,
        24: 20,
        36: 30,
        48: 40,
        // 60: 50,
        // 72: 60,
        // 84: 70,
        max: 50,
      },
      postfix: ' %',
      indexToInitialDisplay: 0,
    },
  },
  installment: {
    period: {
      values: {
        min: 2,
        20: 6,
        40: 12,
        60: 18,
        80: 24,
        max: 36,
        // 60: 48,
        // 72: 60,
        // 84: 72,
        // max: 84,
      },
      postfix: null,
      indexToInitialDisplay: -1,
    },
    payment: {
      values: {
        min: 0,
        14: 10,
        28: 20,
        42: 30,
        56: 40,
        70: 50,
        84: 60,
        max: 70,
        // max: 80,
      },
      postfix: ' %',
      indexToInitialDisplay: 0,
    },
  },
};

export default defineAppConfig(config);
