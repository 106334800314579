<template>
  <NuxtLayout name="default">
    <main class="page-main">
      <div class="container">
        <section class="page-main__not-found not-found">
          <div class="not-found__block">
            <NuxtImg
              src="/img/404@2x.webp"
              class="not-found__img"
            />
            <h1 class="error__title">Страница не найдена</h1>
            <NuxtLink
              class="button not-found__button"
              to="/"
            >
              На главную
            </NuxtLink>
          </div>
        </section>
      </div>
    </main>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

defineProps<{
  error: NuxtError
}>();

const event = useRequestEvent()!;
setResponseStatus(event, 404);
</script>
