import revive_payload_client_4sVQNw7RlN from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import favorites_client_pujl2aaN5r from "/home/d/didisher2/avalon-cars.ru/www/plugins/favorites.client.ts";
import payload_client_yVLowv6hDl from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/d/didisher2/avalon-cars.ru/www/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/d/didisher2/avalon-cars.ru/www/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import primevue_plugin_egKpok8Auk from "/home/d/didisher2/avalon-cars.ru/www/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/home/d/didisher2/avalon-cars.ru/www/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_t2GMTTFnMT from "/home/d/didisher2/avalon-cars.ru/www/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/home/d/didisher2/avalon-cars.ru/www/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import libs_client_LqcPnL2NR5 from "/home/d/didisher2/avalon-cars.ru/www/plugins/libs.client.ts";
import _01_providers_VrjDnFua41 from "/home/d/didisher2/avalon-cars.ru/www/plugins/01.providers.ts";
import counters_ApDJ76BzzG from "/home/d/didisher2/avalon-cars.ru/www/plugins/counters.ts";
import seo_6xeD0pAsTm from "/home/d/didisher2/avalon-cars.ru/www/plugins/seo.ts";
import settings_ZJy0LLHwcO from "/home/d/didisher2/avalon-cars.ru/www/plugins/settings.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  favorites_client_pujl2aaN5r,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  plugin_t2GMTTFnMT,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  libs_client_LqcPnL2NR5,
  _01_providers_VrjDnFua41,
  counters_ApDJ76BzzG,
  seo_6xeD0pAsTm,
  settings_ZJy0LLHwcO
]