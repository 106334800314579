const NEW_ROUTE = 'cars-new';
const NEW_ROUTE_OFFERS = 'new';
export const useCategory = (...category: string[]) => ({
  categoryNew: NEW_ROUTE_OFFERS,
  categoryNewStock: NEW_ROUTE,
  isNew: () => category?.some((i) => i === NEW_ROUTE_OFFERS || i === NEW_ROUTE),
  isNewCatalog: () => category?.some((i) => i === NEW_ROUTE),
  transform() {
    const transformed = category.map((i) => (i === NEW_ROUTE ? NEW_ROUTE_OFFERS : i));
    return category.length === 1 ? transformed[0] : transformed;
  },
  findByName() {
    const { categories } = useAppConfig();
    const transformed = Object.values(categories).filter((i) => category.includes(i.route));
    return category.length === 1 ? transformed[0] : transformed;
  },
});
